@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
body {
  font-family: 'Poppins'  !important;
  margin: 0px;
  color: white;
}

code {
  font-family: 'Poppins'  !important;
  color: white;
}

.antd-layout {
  font-family: inherit !important;
  color: white;
}

.antd-layout {
  font-family: inherit !important;
  color: white !important;
}
.antd-layout-footer {
  font-family: inherit !important;
}

a, p, span, h1, h2, h3, h4, h5, tr, th, td{
  font-family: 'Poppins' !important;
  color: white;
}

.image-section {
  height: 100vh; 
  width: 50%; 
  background-image: url("/public/assets/banner_full.svg");
  background-size: cover;
  background-position: top;
}

.flexbox-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flexbox-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.flexbox-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flexbox-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}





.page-wrapper {
  display: flex;
  flex-direction: column;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.content-section {
  flex: 1;
  min-width: 50%; 
}

@media (max-width: 768px) {
  .image-section,
  .content-section {
    width: 100%; 
  }

  .image-section {
    height: 40vh;
  }

  :where(.css-dev-only-do-not-override-txh9fw).ant-layout-footer {
    padding: 24px 40px;
  }

  .content-wrapper {
    margin: 2rem 0px;
  }

  .green-title-text{
    font-size: 30px !important;
  }

  .iphone-btn {
    width: 200px  !important;
  }

  .android-btn {
    width: 200px  !important;
  }

  .header-text {
    text-align: center !important;
    margin-right: 0px !important;
  }
}
/* .page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

.footer {
  margin-top: auto; 
  background-color: #324467;
  border-top: 1px solid #5E729A;
  color: white !important;
}

.header {
  background-color: #2A3A5A;
  padding: 0px;
  height: 44px;
}

.content {
  background-color: #324467;
  flex: 1;
  /* overflow-y: scroll; */
  scrollbar-width: none;
}

.ant-layout-content{
  min-height: max-content;
}

.content::-webkit-scrollbar {
  display: none;
}

.content-wrapper {
  margin: 2rem 2.5rem;
}

.center {
  text-align: center;
}

.header-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  text-align: right;
  text-transform: uppercase;
  margin-right: 10px;
}

.input-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
  text-transform: uppercase;
}

.input-text-red {
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
  color: #ff4d4f;
}

.green-title-text{
  color: #0dab84;
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
}

.success-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.description-text {
  font-size: 16px;
  font-weight: 300;
}

.subtitle-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.green-subtitle {
  color: #0dab84;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  text-transform: uppercase;
}

.input {
  width:100%;
  background-color: #2F3F5E;
  border-color: #0dab84;
  color: white;
}

.input .ant-input-placeholder {
  color: #0dab84 !important;
}
.input:hover {
  border-color: #0dab84;
}
.input:focus {
  border-color: #0dab84;
}

.green-btn {
  background-color: #0dab84;
  border-color: #0dab84;
  color: #0dab84;
  width: 100%;
  margin: 30px 0px;
  text-transform: uppercase;
}

.green-btn:hover {
  border-color: #0dab84;
}

.iphone-btn {
  background-color: #1264e0;
  border-color: #1264e0;
  color: #1264e0;
  width: 300px;
  margin: 30px 0px;
  height: 40px !important;
}

.iphone-btn:hover {
  border-color: #1264e0;
}

.android-btn {
  background-color: #0dab84;
  border-color: #0dab84;
  color: #0dab84;
  width: 300px;
  height: 40px !important;
}

.android-btn:hover {
  border-color: #0dab84;
}

.footer-content{
  text-align: center;
  display: inline-block;
}

.footer-header-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #0dab84;
}

.footer-copyright {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
}

.green-text {
  color: #0dab84;
}

.green-border-container {
  width: 100%;
  border-bottom: 1px solid #0dab84;
}

.btn-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 2px;
}

.btn-subtitle {
  color: #ffffff;
  font-size: 8.5px;
  font-weight: 500;
  text-align: left;
}

.logo-img {
  margin: auto;
  padding: auto;
}





