.member-form-container {
    margin: 30px 0px;
    padding: 20px 30px;
}

.checkbox-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}


.form-checkbox {
    border-color: #192337;
}

.ant-checkbox-input {
    background-color: #546992;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0dab84;
    border-color: #192337;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    background-color: #0dab84;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
    border-color: #192337 !important;
}

.select_style .ant-select-selector {
    background-color: #2F3F5E !important;
    border: 1px solid #0dab84 !important;
    background: #2F3F5E !important;
    
}

.select_style .ant-select-selection-placeholder {
    color: #0dab84 !important;
}
.ant-input::placeholder {
    color: #0dab84 !important;
}
.select_style .ant-select-dropdown-menu-item-active {
    background-color: #2F3F5E !important;
}

.select_style .ant-select-selecto:hover{
    border: 1px solid #0dab84 !important;
}

:where(.css-dev-only-do-not-override-swt6e8).ant-select-dropdown {
    background-color: #2F3F5E  !important;
    color: white !important;
}

:where(.css-dev-only-do-not-override-swt6e8).ant-select-dropdown .ant-select-item {
    color: white !important;
}
:where(.css-dev-only-do-not-override-swt6e8).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #0dab84;
}
.inputInfo {
    background: white;
    border-radius: 5px;
    padding: 0.3em 0.5em;
    display: inline-block;
    color: red;
    font-size: 0.8em;
}
@media (max-width: 768px) {
    .member-form-container {
        padding: 20px 0px;
    }
  
  }