.member-code-container {
    border: 1px solid #5E729A;
    margin: 30px 0px;
    padding: 20px 30px;
}

.uppercase {
    text-transform: uppercase;
}
.inputInfo {
    background: white;
    border-radius: 5px;
    padding: 0.3em 0.5em;
    display: inline-block;
    color: red;
    font-size: 0.8em;
}